/* apps/outdoorPro/src/app/home/home.component.scss */
.home-logo {
  height: 400px;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .home-logo {
    height: 280px;
  }
}
.decathlon-logo {
  max-width: 300px;
  width: 100%;
  height: auto;
}
.homepage-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: url(/assets/background.jpeg) no-repeat center center fixed;
  background-size: cover;
}
.homepage-wrapper > div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 15px;
}
.homepage-wrapper > div > div {
  width: 100%;
}
.social-media a {
  font-size: 3em;
  padding: 0 15px;
  cursor: pointer;
}
/*# sourceMappingURL=home.component.css.map */
